.seprated_row {
  border: 1px solid #666;
  padding: 20px 0px;
  margin: 20px 0px;
  border-radius: 10px;
}

.bundle_table {
  width: 100%;
}

.bundle_table td {
  border-bottom: 1px solid #eeeeee;
}
