.top_nav {
  background: rgb(79, 79, 79);
}

.top_nav_item {
  padding: 10px 20px;
  color: white;
}

.top_nav_logout {
  padding: 10px 20px;
  font-weight: 500;
  color: yellow;
  background-color: tomato;
  float: right;
  cursor: pointer;
}