.title_container {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
}

.title_container .image {
    width: 100%;
}

h2 {
    margin: 20px 0px;
}

.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.editor-class {
    background-color: white;
    border: 0px;
}

.wrapper-class {
    background: white;
}

.flag-options {
    border: 1px solid gray;
    border-radius: 5px;
}