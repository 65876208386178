.formik_error {
  color: orangered;
}

.seprated_row {
  border: 1px solid #666;
  padding: 20px 0px;
  margin: 20px 0px;
  border-radius: 10px;
}
