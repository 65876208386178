body {
  background: #eeeeee;
}
.container_div {
  text-align: left;
  background: white;
  padding: 10px;
  width: 100%;
  max-width: 1400px !important;
}

.row {
  margin: 0px !important;
}

.no-padding {
  padding: 0px;
  margin: 0px;
}

.padding-side-5 {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
}

.spinner_container {
  text-align: center;
  padding: 30px 0px;
}

.custom_formik_col label {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.custom_formik_col input {
  width: 100%;
  border-radius: 6px;
  box-shadow: none;
  border: 1px #aaaaaa solid;
}

.custom_formik_col select {
  width: 100%;
  height: 30px;
}

.custom_formik_col textarea {
  width: 100%;
  border-radius: 6px;
  box-shadow: none;
  border: 1px #aaaaaa solid;
}
