.bold-cell {
    text-transform: uppercase;
}

.cap-cell {
    text-transform: capitalize;
}

.select_group {
    border-bottom: 1px solid #eee;
    font-size: 15px !important;
    background-color: #eee !important;
    font-weight: 600 !important;
}

.card_container {
    padding: 10px 0px;
}

.card_title {
    background-color: #1992AF;
    color: white;
    padding: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: gray solid 1px;
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
}

.card_title_left {
    flex: 33%;
}

.card_title_right {
    flex: 33%;
    text-align: right;
}

.card_title_center {
    flex: 33%;
    text-align: center;
}

.card_body {
    border: gray solid 1px;
}

.card_section_container {
    border-bottom: 1px solid #eee;
}

.card_section {
    padding: 5px;
}

.card_section:nth-child(odd) {
    background: white;
}

.card_section:nth-child(even) {
    background: white;

}

.item_container {
    display: flex;
    flex-direction: row;
}

.item_container:nth-child(odd) {
    background: white;
}

.item_container:nth-child(even) {
    background: #eee;

}

.item_sub {
    display: flex;
    flex-direction: column;
    padding: 5px;
    font-size: 13px;
}

.item_sub>img {
    width: 60px;
    
}

