/* .table_cell {
  border: 1px solid black;
}

.tick_icon {
  color: rgb(41, 164, 125);
}

.cross_icon {
  color: rgb(196, 35, 35);
}
 */

.table_img {
  width: 50px;
}

.table_input_url {
  width: 150px;
  font-size: 12px;
}

.table_input_weight {
  font-size: 12px;
}

.table_cell_isbn {
  width: 150px;
}

.table_cell_name {
  width: 100%;
}

.table_cell_img {
  width: 300px;
  max-width: 150px;
}

.table_cell_save {
  vertical-align: middle;
}

.table_cell_result {
  vertical-align: middle;
}